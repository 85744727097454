<section class="section info-container">
  <div class="info_panel">
    <div class="left_section dark_section">
      <i class="bi bi-speedometer2 text-danger"></i>
    </div>
    <div class="right_section info_section">
      <h1 class="text-center info-title">
        {{ "home-page.info-section.speed.title" | translate }}
      </h1>
      <p class="text-center p-3">
        {{ "home-page.info-section.speed.description" | translate }}
      </p>
    </div>
  </div>
  <div class="info_panel">
    <div class="left_section info_section">
      <h1 class="text-center info-title">
        {{ "home-page.info-section.affordable.title" | translate }}
      </h1>
      <p class="text-center p-3">
        {{ "home-page.info-section.affordable.description" | translate }}
      </p>
    </div>
    <div class="right_section dark_section">
      <i class="bi bi-wallet2 text-warning"></i>
    </div>
  </div>
  <div class="info_panel">
    <div class="right_section info_section">
      <h1 class="text-center info-title">
        {{ "home-page.info-section.reliable.title" | translate }}
      </h1>
      <p class="text-center">
        {{ "home-page.info-section.reliable.description" | translate }}
      </p>
    </div>
    <div class="left_section dark_section">
      <i class="bi bi-patch-check text-success"></i>
    </div>
  </div>
</section>
