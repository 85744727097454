import { Injectable } from "@angular/core";
import { ScriptStore } from "../../models/Script.store";

@Injectable({
  providedIn: "root",
})
export class ScriptService {
  private scripts: any = {};
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        async: script.async,
        defer: script.defer,
        data_resources_url: script.data_resources_url,
        data_widget_id: script.data_widget_id,
      };
    });
  }
  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      } else {
        //load script
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        script.async = this.scripts[name].async;
        script.defer = this.scripts[name].defer;
        if (this.scripts[name].data_resources_url) {
          script.setAttribute(
            "data-resources-url",
            this.scripts[name].data_resources_url
          );
        }
        if (this.scripts[name].data_widget_id) {
          script.setAttribute(
            "data-widget-id",
            this.scripts[name].data_widget_id
          );
        }
        document.getElementsByTagName("head")[0].appendChild(script);
        resolve({ script: name, loaded: true, status: "Loaded" });
      }
    });
  }
}
