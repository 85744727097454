<div class="container">
  <CLM-user-data></CLM-user-data>
  <div class="row justify-content-center mt-5">
    <div class="col-md-6 col-sm-8 col-xs-10 mt-2">
      <div class="form-check">
        <input
          [(ngModel)]="check_2"
          class="form-check-input"
          type="checkbox"
          value=""
          id="check_2"
        />
        <label class="form-check-label" for="check_2">
          {{ "cart.order.check_before" | translate }}
          <a [href]="'footer.docs.privacy_policy' | translate" target="_blank"
            >{{ "cart.order.check_2" | translate }}
          </a>
        </label>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <button
      class="btn btn-outline-danger mt-2 mb-5 col-6"
      [disabled]="!user?.userForm?.valid || !check_2"
      (click)="register()"
    >
      {{ "register.register" | translate }}
    </button>
  </div>
</div>
