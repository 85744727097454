import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { UserDataComponent } from "../../../Pages/user/register/user-data/user-data.component";
import { TranslateModule } from "@ngx-translate/core";
import { ApiService } from "../../Services/Api/api.service";
import { UserService } from "../../Services/User/user.service";
import { ToastService } from "../../Toast/toast.service";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "CLM-register",
  standalone: true,
  imports: [UserDataComponent, TranslateModule, FormsModule],
  templateUrl: "./register.component.html",
  styleUrl: "./register.component.scss",
})
export class RegisterComponent {
  @ViewChild(UserDataComponent) user?: UserDataComponent;
  @Output() registered: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private toastService: ToastService
  ) {}

  check_2: boolean = false;

  register() {
    if (this.user) {
      this.apiService.UserRegister(this.user?.userForm).subscribe({
        next: (response) => {
          if (response.result) {
            if (this.user?.userForm.controls.email.value) {
              this.userService
                .login(
                  this.user.userForm.controls.email.value,
                  this.user?.userForm.controls.password.value
                )
                .subscribe({
                  next: (value) => {
                    if (value.result && value.data) {
                      localStorage.setItem("apitoken", value.data);
                      this.userService.getUserData();
                      this.registered.emit(true);
                    }
                  },
                });
            }
          } else {
            this.toastService.showAlert(response.error);
          }
        },
      });
    }
  }
}
