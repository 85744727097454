<section *ngIf="vps_category" class="section categories-section">
  <div class="vps-panel">
    <div
      class="vps-title vps-left"
      (click)="scrollService.scrollTo('Cost effective')"
    >
      Cost Effective
    </div>
    <div class="vps-title" (click)="scrollService.scrollTo('Standard')">
      Standard
    </div>
    <div
      class="vps-title vps-right"
      (click)="scrollService.scrollTo('Highend')"
    >
      High-end
    </div>
  </div>
  <app-vps-subcategories-page
    [id]="category.name"
    *ngFor="let category of vps_category.sub_categories"
    [vps_category]="category"
    [currency]="currencyService.getCurrentCurrency()"
    class="pt-5"
  ></app-vps-subcategories-page>
</section>
